import React, { useState } from 'react';
import OpenAI from "openai";
import Footer from './footer';
import Header from './header';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const openai = new OpenAI({apiKey: API_KEY, dangerouslyAllowBrowser: true});

const Loading = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
      <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin"></div>
    </div>
  );
};

function App() {
  const [prompt, setPrompt] = useState('');
  const [images, setImages] = useState([]);
  const [loading, isLoading] = useState(false);

  
  const generateImages = async () => {
    isLoading(true);
    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
        size: "1024x1024",
      })
      setImages(response.data);
    } catch (error) {
      console.error('Error generating images:', error);
    } finally {
      isLoading(false);
    }
  };


  return (
    <div className="flex flex-col justity-between h-screen ">
      <div>
        <Header />
      </div>

      <div className="container mx-auto my-8 p-4 text-center border-2 max-h-fit md:w-auto" >  
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter a prompt"
          className="border-2 p-2 mb-4"
        />
        <button onClick={generateImages} className="bg-blue-500 text-white mx-2 px-4 py-2 rounded">
          Generate Images
        </button>
        {loading ? <Loading/> :  
        <div className="max-w-96 m-2 md:w-auto">
          {images.map((image, index) => (
            <img key={index} src={image.url} alt={''} className="max-w-full mb-4" />
          ))}
          
        </div>} 
      </div>
    
      <div>
        <Footer />
      </div>

    </div>
    
  );
}

export default App;

