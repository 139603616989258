

export default function Footer() {


    return (
        <div className="w-screen md:w-auto h-64 
            border-2 border-indigo-200 p-4 text-center bg-gray-100">
            <p className="text-sm mb-4"> powered <br/>by ANTECH </p>
        </div>
    );

}