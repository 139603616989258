

export default function Header() {


    return (
        <div className="w-screen md:w-auto h-24
            border-2 border-indigo-200 p-6 text-center bg-gray-100">
            <h1 className="text-4xl font-bold mb-4">Image Generator</h1>
        </div>
    );

}